import animateScrollTo from './animateScrollTo'
import 'featherlight'
import './grensesnitt-lightbox'
import './block-adaptations'
import './block-slider'
import './block-team'
import './block-faq'
import './block-expandable-sections'
import './block-specifications'
import './menu-scroll'
import './form'
import './cookie-banner'
import './category-filter'
import './contact-form'
import './product-tables';

import { initMap } from "./maps";

window.initMap = initMap;

var topButtons = document.getElementsByClassName('go-to-top');
for (var i = 0; i < topButtons.length; i++) {
    var topButton = topButtons[i];

    topButton.addEventListener('click', function (e) {
        if (e.preventDefault) {
            e.preventDefault();
        }

        animateScrollTo(
            document.body,
            700,
            'easeInOutCubic'
        );
    });
}

var navIcon = document.getElementById('nav-icon');
if (navIcon) {
    navIcon.addEventListener('click', function () {
        var header = document.getElementsByTagName('header')[0];
        var body = document.getElementsByTagName('body')[0];

        if (navIcon.classList.contains('open')) {
            navIcon.classList.remove('open');
            header.classList.remove('nav-mobile-show');
        } else {
            navIcon.classList.add('open');
            header.classList.add('nav-mobile-show');
        }
    });
}

//$('.lightbox').featherlight();