(function () {

    var $offer = $('#form-offer');

    function showOfferForm() {
        showForm($offer);
    }

    function hideOfferForm() {
        hideForm($offer);
    }

    $('a[href="#tilbud"]').click(function (e) {
        e.preventDefault();

        setProductMetadata($(this));

        renderOfferForm();
        showOfferForm();
    });

    $('a[href="#tilbud-port"]').click(function (e) {
        e.preventDefault();

        setProductMetadata($(this));

        offerData.step = 0;
        renderOfferForm();
        showOfferForm();
    });

    $('a[href="#tilbud-maltaking"]').click(function (e) {
        e.preventDefault();

        setProductMetadata($(this));

        offerData.step = 1;
        renderOfferForm();
        showOfferForm();
    });

    function setProductMetadata($link) {
        if (offerData.gate) {
            return;
        }

        var $parent = $link.parents('.step-process');

        var $targetGroupIdInput = $parent.find('.target-group');
        if ($targetGroupIdInput.length > 0) {
            var targetGroup = $targetGroupIdInput.val();
            if (targetGroup) {
                offerData.targetGroup = targetGroup;
            }
        }

        var $productIdInput = $parent.find('.product-id');
        if ($productIdInput.length > 0) {
            var productId = parseInt($productIdInput[0].value) || 0;

            if (productId) {
                var $option = $offer.find('.product-categories').find('option[value="' + productId + '"]');
                $option.parents('select').val(productId);

                if ($option.length > 0) {
                    offerData.gate = $option.data('product');

                    var targetGroup = $option.parents('.product-categories').data('targetGroup');
                    if (targetGroup) {
                        offerData.targetGroup = targetGroup;
                    }

                    $offer.find('#gate').val(offerData.gate ? offerData.gate.id : '');
                }
            }
        }
    }

    var offerData = {
        targetGroup: 'privat',
        gate: null,
        step: 0,
        measurements: {
            width: 0,
            height: 0,
            sideLeft: 0,
            sideRight: 0,
            overHeight: 0,
            depth: 0
        },
        inspection: true,
        personalData: {
            projectType: null,
            firstName: '',
            lastName: '',
            address: '',
            postalCode: 0,
            city: '',
            email: '',
            phone: 0
        }
    };

    var hasValidatedOfferData = false;

    $offer.click(function (e) {
        var $target = $(e.target);
        if (!$target.hasClass('inner') && $target.parents('.inner').length === 0) {
            hideOfferForm();
        }
    });

    $offer.submit(function (e) {
        e.preventDefault();

        var $form = $(this).parent().find('form');

        if (validate($form)) {
            sendEmail($form);
        }
    });

    $offer.find('h2 a').click(function (e) {
        e.preventDefault();

        var step = parseInt($(this).data('step')) || 0;
        offerData.step = offerData.step === step ? -1 : step;

        renderOfferForm()
    });

    $offer.find('a.next-step').click(function (e) {
        e.preventDefault();

        var step = parseInt($(this).data('step')) || 0;
        offerData.step = step + 1;
        renderOfferForm();
    });

    $offer.find('input[name="target_group"]').change(function () {
        offerData.targetGroup = $(this).val();
        renderOfferForm();
    });

    $offer.find('select.gate').change(function () {
        var $select = $(this);
        var $option = $select.find('option[value="' + $select.val() + '"]');

        if ($option) {
            offerData.gate = $option.data('product');

        } else {
            offerData.gate = null;
        }

        $offer.find('#gate').val(offerData.gate ? offerData.gate.id : '');

        $offer.find('select.gate').each(function (i, select) {
            var $s = $(select);
            if (select !== $select[0]) {
                $s.val('');
            }
        });

        renderOfferForm();
    });

    $offer.find('#inspection').change(function () {
        offerData.inspection = this.checked;
    });

    $offer.find('.progress-status').find('.change').click(function (e) {
        e.preventDefault();

        var step = $(this).data('step') || 0;
        offerData.step = step;
        renderOfferForm();
    });

    $offer.find('a.close').click(function (e) {
        e.preventDefault();

        hideOfferForm();
    });

    function renderOfferForm() {
        // Select correct target group
        var $targetGroup = $offer.find('input[name="target_group"]');
        $targetGroup.prop('checked', false);
        $targetGroup.each(function (i, input) {
            var $input = $(input);
            var isChecked = $input.val() === offerData.targetGroup;
            $input.prop('checked', isChecked);
        });

        // Show correct product category
        var $productCategories = $offer.find('.product-categories');
        $productCategories.each(function (i, cat) {
            var $cat = $(cat);
            if ($cat.data('targetGroup') === offerData.targetGroup) {
                $cat.show();
            } else {
                $cat.hide();
            }
        });

        // Update gate progress
        $offer.find('.progress-status').find('.gate span').html(offerData.gate ? offerData.gate.title : 'Ikke valgt');

        // Update measurements progress
        var measurements = getMeasurements();
        var mStrings = [];
        if (measurements.width) {
            mStrings.push('Bredde: ' + measurements.width);
        }
        if (measurements.height) {
            mStrings.push('Høyde: ' + measurements.height);
        }
        if (measurements.sideLeft) {
            mStrings.push('Side V: ' + measurements.sideLeft);
        }
        if (measurements.sideRight) {
            mStrings.push('Side H: ' + measurements.sideRight);
        }
        if (measurements.overHeight) {
            mStrings.push('Over: ' + measurements.overHeight);
        }
        if (measurements.depth) {
            mStrings.push('Dybde: ' + measurements.depth);
        }
        $offer.find('.progress-status').find('.measurements span').html(mStrings.length > 0 ? mStrings.join(', ') : 'Ikke valgt');

        // Show correct step
        var $steps = $offer.find('.step');
        $steps.removeClass('active');
        if (offerData.step >= 0 && offerData.step < $steps.length) {
            $($steps[offerData.step]).addClass('active');
        }

        var $stepContent = $steps.find('.content');
        $stepContent.each(function (i, step) {
            if (i === offerData.step) {
                $(this).slideDown();
            } else {
                $(this).slideUp();
            }
        });

        // Validate if needed
        if (hasValidatedOfferData) {
            validate();
        }
    }

    function validate() {
        var $errorReport = $offer.find('.error-report');
        var $errorReportList = $errorReport.find('ul');

        // Remove stale validation classes
        $offer.find('.has-error').removeClass('has-error');
        $errorReport.hide();
        $errorReportList.html('');

        // Validate gate selection
        if (!offerData.gate) {
            $offer.find('select.gate').parents('.form-group').addClass('has-error');
            $offer.find('.progress-status .gate .selection').addClass('has-error');
            $errorReportList.append('<li>Port er ikke valgt</li>');
        }

        // Validate measurements
        var measurements = getMeasurements();
        if (!measurements.width) {
            $offer.find('#gate-width').parents('.form-group').addClass('has-error');
            $offer.find('.progress-status .measurements .selection').addClass('has-error');
            $errorReportList.append('<li>Breddemål er ikke fylt ut</li>');
        }

        if (!measurements.height) {
            $offer.find('#gate-height').parents('.form-group').addClass('has-error');
            $offer.find('.progress-status .measurements .selection').addClass('has-error');
            $errorReportList.append('<li>Høydemål er ikke fylt ut</li>');
        }

        // Validate personal data
        var personalData = getPersonalData($offer);

        if (!personalData.firstname) {
            $offer.find('#firstname').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Fornavn er ikke fylt ut</li>');
        }

        if (!personalData.lastname) {
            $offer.find('#lastname').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Etternavn er ikke fylt ut</li>');
        }

        if (!personalData.email) {
            $offer.find('#email').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Epost er ikke fylt ut</li>');
        }

        var hasErrors = $offer.find('.has-error').length > 0;

        if (hasErrors) {
            $errorReport.show();
        }

        hasValidatedOfferData = true;

        return !hasErrors;
    }

    function getMeasurements() {
        var width = $offer.find('#gate-width').val();
        var height = $offer.find('#gate-height').val();
        var sideLeft = $offer.find('#gate-side-left').val();
        var sideRight = $offer.find('#gate-side-right').val();
        var overHeight = $offer.find('#garage-over-height').val();
        var depth = $offer.find('#garage-depth').val();

        return {
            width: width,
            height: height,
            sideLeft: sideLeft,
            sideRight: sideRight,
            overHeight: overHeight,
            depth: depth
        }
    }

    renderOfferForm();

})();

(function () {

    var $meeting = $('#form-meeting');

    function showMeetingForm() {
        showForm($meeting);
    }

    function hideMeetingForm() {
        hideForm($meeting);
    }

    $('a[href="#mote"]').click(function (e) {
        e.preventDefault();

        showMeetingForm();
    });

    $('a[href="#mote-befaring"]').click(function (e) {
        e.preventDefault();

        $meeting.find('#meeting-type').val('Befaring');
        showMeetingForm();
    });

    $meeting.click(function (e) {
        var $target = $(e.target);
        if (!$target.hasClass('inner') && $target.parents('.inner').length === 0) {
            hideMeetingForm();
        }
    });

    $meeting.find('a.close').click(function (e) {
        e.preventDefault();

        hideMeetingForm();
    });

    $meeting.submit(function (e) {
        e.preventDefault();

        var $form = $(this).parent().find('form');

        if (validate($form)) {
            sendEmail($form);
        }
    });

    $('.date-wrapper').find('img').click(function() {
        var $input = $(this).siblings('input');
        $input.focus();
        $input.click();
    });

    function validate($form) {
        var $errorReport = $form.find('.error-report');
        var $errorReportList = $errorReport.find('ul');
        var $successMessage = $form.find('.success-message');

        // Remove stale validation classes
        $form.find('.has-error').removeClass('has-error');
        $errorReport.hide();
        $successMessage.hide();
        $errorReportList.html('');

        // Validate personal data
        var personalData = getPersonalData($form);

        if (!personalData.firstname) {
            $form.find('#firstname').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Fornavn er ikke fylt ut</li>');
        }

        if (!personalData.lastname) {
            $form.find('#lastname').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Etternavn er ikke fylt ut</li>');
        }

        if (!personalData.email) {
            $form.find('#email').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Epost er ikke fylt ut</li>');
        }

        var hasErrors = $form.find('.has-error').length > 0;

        if (hasErrors) {
            $errorReport.show();
        }

        return !hasErrors;
    }

})();

(function () {

    var $service = $('#form-service');

    function showServiceForm() {
        showForm($service);
    }

    function hideServiceForm() {
        hideForm($service);
    }

    $('a[href="#serviceavtale"]').click(function (e) {
        e.preventDefault();

        showServiceForm();
    });

    $service.click(function (e) {
        var $target = $(e.target);
        if (!$target.hasClass('inner') && $target.parents('.inner').length === 0) {
            hideServiceForm();
        }
    });

    $service.find('a.close').click(function (e) {
        e.preventDefault();

        hideServiceForm();
    });

    $service.submit(function (e) {
        e.preventDefault();

        var $form = $(this).parent().find('form');

        if (validate($form)) {
            sendEmail($form);
        }
    });

    function validate($form) {
        var $errorReport = $form.find('.error-report');
        var $errorReportList = $errorReport.find('ul');
        var $successMessage = $form.find('.success-message');

        // Remove stale validation classes
        $form.find('.has-error').removeClass('has-error');
        $errorReport.hide();
        $successMessage.hide();
        $errorReportList.html('');

        // Validate personal data
        var personalData = getPersonalData($form);

        if (!personalData.firstname) {
            $form.find('#firstname').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Fornavn er ikke fylt ut</li>');
        }

        if (!personalData.lastname) {
            $form.find('#lastname').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Etternavn er ikke fylt ut</li>');
        }

        if (!personalData.email) {
            $form.find('#email').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Epost er ikke fylt ut</li>');
        }

        var hasErrors = $form.find('.has-error').length > 0;

        if (hasErrors) {
            $errorReport.show();
        }

        return !hasErrors;
    }

})();

(function () {

    var $contact = $('.gapo-form#form-contact, .gapo-form#team-form-contact, .gapo-form#overlay-form-contact');

    $contact.find('.display').css('transform', 'translateX(0)');

    function showContactForm(name, email) {
        $contact.css({
            'visibility': 'initial',
            'overflow-y': 'auto'
        });
        $contact.find('.form-title').html('Kontakt ' + name);
        $contact.find('.target_e').attr('value', email);
        $contact.find('.overlay').css('opacity', 1);

        $contact.find('.inner').css({
            'padding': '1em 2em 2em 2em',
            'overflow-y': 'auto'
        });
        $contact.find('form').css('margin-bottom', '0');
        $contact.find('.submit-button').css('margin-top', '0');
        $contact.find('.form-group').css('margin', '1em 0');
        $contact.find('h2').css('margin-top', '0');

        $contact.find('.error-report').css('margin', '2em 0');

        if ($(window).width() > 600) {
            $contact.find('.display').css({
                'flex-direction': 'column',
                'margin': 'auto auto',
                'height': 'max-content',
                'padding': '2em'
            });
            $contact.find('#copy').css({
                'font-size': '0.9rem',
                'margin': '0.4rem 0 1em 0'
            });

            $contact.find('.close').css({
                'position': 'absolute',
                'top': 0,
                'right': 0,
                'margin': '3em',
                'z-index': 1
            });
        }
        else {
            $contact.find('.display').css({
                'flex-direction': 'column',
                'margin': 'auto auto',
                'padding': '2em',
                'height': '100%'
            });
            $contact.find('#copy').css({
                'font-size': '0.8rem',
                'margin': '0.2rem 0 1em 0'
            });

            $contact.find('.close').css({
                'position': 'absolute',
                'top': 0,
                'right': 0,
                'margin': '2.5em',
                'z-index': 1
            });
        }

        if($(window).width() > 1000) {
            $contact.find('.inner').css({
                'padding': '3em',
            });
        }

        if($(window).width() > 1200) {
            $contact.find('.contact-info').css({
                'display': 'flex',
                'justify-content': 'space-between',
                'width': '100%'   
            });
            $contact.find('#contactform-email').css('width', '58%');
            $contact.find('#copy').css('margin-bottom', '0');
            $contact.find('#contactform-phone').children('label').css('white-space', 'nowrap');
            $contact.find('#contactform-phone').css('width', '40%');
        }
    }

    function hideContactForm() {
        $contact.find('.overlay').css('opacity', 0);
        $contact.css('visibility', 'hidden');
    }

    $('a[href="#contact"]').click(function (e) {
        e.preventDefault();
        var contactName = $(this).data('name');
        var email = $(this).data('email');

        showContactForm(contactName, email);
    });

    $contact.click(function (e) {
        var $target = $(e.target);
        if (!$target.hasClass('inner') && $target.parents('.inner').length === 0) {
            hideContactForm();
        }
    });

    $contact.find('a.close').click(function (e) {
        e.preventDefault();

        hideContactForm();
    });

    $contact.submit(function (e) {
        e.preventDefault();

        var $form = $(this).parent().find('form');

        if (validate($form)) {
            sendEmail($form);
        }
    });

    function validate($form) {
        var $errorReport = $form.find('.error-report');
        var $errorReportList = $errorReport.find('ul');
        var $successMessage = $form.find('.success-message');

        // Remove stale validation classes
        $form.find('.has-error').removeClass('has-error');
        $errorReport.hide();
        $successMessage.hide();
        $errorReportList.html('');

        // Validate personal data
        var personalData = getPersonalData($form);

        if (!personalData.name) {
            $form.find('#name').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Navn er ikke fylt ut</li>');
        }

        if (!personalData.email) {
            $form.find('#email').addClass('has-error');
            $form.find('#email').parents('.form-group').children('div').children('#email-label').addClass('has-error');
            $errorReportList.append('<li>Epost er ikke fylt ut</li>');
            $form.find('.has-error').css({
                'color': '#b22020',
                'border-color': '#b22020'
            });
        }

        if (!personalData.message) {
            $form.find('#message').parents('.form-group').addClass('has-error');
            $errorReportList.append('<li>Melding er ikke fylt ut</li>');
        }

        var hasErrors = $form.find('.has-error').length > 0;

        if (hasErrors) {
            $errorReport.show();
        }

        return !hasErrors;
    }

})();

function getPersonalData($root) {
    var name = $root.find('#name').val();
    var message = $root.find('#message').val();
    var files = $root.find('#files').val();
    var firstname = $root.find('#firstname').val();
    var lastname = $root.find('#lastname').val();
    var address = $root.find('#address').val();
    var postalCode = $root.find('#postal-code').val();
    var city = $root.find('#city').val();
    var email = $root.find('#email').val();
    var phone = $root.find('#phone').val();

    return {
        name: name,
        message: message,
        files : files,
        firstname: firstname,
        lastname: lastname,
        address: address,
        postalCode: postalCode,
        city: city,
        email: email,
        phone: phone
    }
}

function showForm($form) {
    $form.css('visibility', 'initial');
    $form.find('.overlay').css('opacity', 1);

    setTimeout(function() {
        $form.find('.display').css('transform', 'initial');
    }, 150);

}

function hideForm($form) {
    $form.find('.display').css('transform', 'translateX(100%)');

    setTimeout(function() {
        $form.find('.overlay').css('opacity', 0);

        setTimeout(function() {
            $form.css('visibility', 'hidden');
        }, 250);
    }, 150);
}

function sendEmail($form) {
    let formData = $form.serializeArray();

    let data = new FormData();
    data.append('url', window.location.href);
    data.append('action', 'submit_extended_contact_form');

    for (let i = 0; i < formData.length; i++) {
        let name = formData[i]['name'];
        let value = formData[i]['value'];
        data.append(name, value);
    }

    $.ajax({
        url: gapo_scripts.ajax_url,
        type: 'POST',
        data : data,
        cache: false,
        processData: false,
        contentType: false,
        success: function() {
            window.location = '/kontakt/takk/';
        },
        error: function(data) {
            var $errorReport = $form.find('.error-report');
            $errorReport.html(data.responseText);
            $errorReport.show(data.responseText);
        }
    });
}
