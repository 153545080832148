
var $questions = $('.faq').find('.question');
$questions.find('a.open-answer').click(function(e) {
    e.preventDefault();

    var $question = $(this).parents('.question');
    var $answer = $question.find('.answer');

    if ($question.hasClass('active')) {
        $question.removeClass('active');
        //$answer.slideUp();
    } else {
        $questions.removeClass('active');
        $question.addClass('active');

        //$answer.slideDown();
    }
});