var styles = [
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#193341"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#2c5a71"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#29768a"
            },
            {
                "lightness": -37
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#406d80"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#406d80"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#3e606f"
            },
            {
                "weight": 2
            },
            {
                "gamma": 0.84
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
            {
                "weight": 0.6
            },
            {
                "color": "#1a3541"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#2c5a71"
            }
        ]
    }
];

export function initMap() {
    var maps = document.getElementsByClassName('map');

    for (var i = 0; i < maps.length; i++) {
        var mapContainer = maps[i];
        var count = mapContainer.getAttribute('data-count');
        var positions = [];
        for (var j = 1; j <= count; j++) {
            var lat = parseFloat(mapContainer.getAttribute('data-lat-' + j)) || 0;
            var lng = parseFloat(mapContainer.getAttribute('data-lng-' + j)) || 0;
            var address = mapContainer.getAttribute('data-address-' + j);

            positions.push({ lat, lng, address });
        }

        if (positions.length < 1) {
            continue;
        }

        var options = {
            center: { lat: positions[0].lat, lng: positions[0].lng },
            zoom: 9,
            styles: styles
        };

        var map = new google.maps.Map(mapContainer, options);
        var bounds = new google.maps.LatLngBounds();

        var marker, position, address;
        for (var j = 0; j < positions.length; j++) {
            position = {
                lat: positions[j].lat,
                lng: positions[j].lng
            };
            address = positions[j].address;
            marker = new google.maps.Marker({
                position: position,
                map: map
            });
            google.maps.event.addListener(marker, 'click', function () {
                window.location.href = 'https://maps.google.com/?q=' + address;
            });
            if (positions.length > 1) {
                bounds.extend(position);
            }
        }
        if (positions.length > 1) {
            map.fitBounds(bounds);
        }
    }
}