var $tagListButton = $('#filter-controls .tag-list .tag-btn');
var $subListButton = $('#filter-controls .tag-list .sub-categories .tag-sub');

$tagListButton.click(function (e) {
    e.preventDefault();
    var slug = this.getAttribute('data-slug');
    var $subTag = $(this).parent('.category').children('.sub-categories').children('.tag-sub');

    if ($(this).hasClass('tag-active')) {
        $(this).removeClass('tag-active');
        $subTag.removeClass('tag-active');
        $('.alleProdukter').removeClass('tag-active');

        if (slug === "alle") {
            $('.tag-list label').removeClass("tag-active");
        }
        else if(($('.tag-btn.tag-active').length === 0) && ($('.tag-sub.tag-active').length === 0)) {
            $('.project').show();
        }
        else {
            $('.project.' + slug).hide();
        }
    }
    else {
        $(this).addClass('tag-active');
        $subTag.addClass('tag-active');

        if (slug === "alle") {
            $('.project').show();
            $('.tag-list label').addClass("tag-active");
        }
        else if (($('.tag-btn.tag-active').length) === ($('.tag-btn').length - 1)) {
            $('.alleProdukter').addClass('tag-active');
            $('.project').show();
        }
        else if (($('.tag-btn.tag-active').length === 1) && (($('.tag-sub.tag-active').length-$subTag.length) > 0)) {
            $('.project.' + slug).show();
        }
        else if (($('.tag-btn.tag-active').length === 1)) {
            $('.project').hide();
            $('.project.' + slug).show();
        }
        else {
            $('.project.' + slug).show();
        }
    }
});

$subListButton.click(function (e) {
    e.preventDefault();
    var slug = this.getAttribute('data-slug');
    if ($(this).hasClass('tag-active')) {
        $(this).removeClass('tag-active');
        $('.project.' + slug).hide();

        if (($(this).parent('.sub-categories').children('.tag-sub').length) != ($(this).parent('.sub-categories').children('.tag-sub.tag-active').length)) {
            $(this).parent('.sub-categories').parent('.category').children('.tag-btn').removeClass('tag-active');
            $('.alleProdukter').removeClass('tag-active');
        }
        if($('.tag-sub.tag-active').length === 0) {
            $('.project').show();
        }
    }
    else {
        $(this).addClass('tag-active');

        if (($(this).parent('.sub-categories').children('.tag-sub').length) === ($(this).parent('.sub-categories').children('.tag-sub.tag-active').length)) {
            $(this).parent('.sub-categories').parent('.category').children('.tag-btn').addClass('tag-active');
            $('.project.' + slug).show();
        }
        else {
            $('.project.' + slug).show();
        }
        if(($('.tag-btn.tag-active').length) === ($('.tag-btn').length - 1)) {
            $('.alleProdukter').addClass('tag-active');
        }
        if ($('.tag-sub.tag-active').length === 1) {
            $('.project').hide();
            $('.project.' + slug).show();
        }
    }
});

/* var hash = window.location.hash;

if (hash && hash.length > 1) {
    hash = hash.substring(1);

    var $tagButtons = $('#filter-controls input[name="' + hash + '"]');
    if ($tagButtons.length > 0) {
        $('.tag-list label').removeClass("tag-active");
        $('.tag-list label[data-slug="' + hash + '"]').addClass("tag-active");
        $('.project').hide();
        $('.project.' + hash).show();
    }
} */