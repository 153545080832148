import Swipe from "swipejs";

function loadSliders() {
    var sliders = document.getElementsByClassName('slider')
    for (var i = 0; i < sliders.length; i++) {

        (function(element) {
            var swipes = document.getElementsByClassName('swipe');

            if (!swipes || swipes.length === 0) {
                return;
            }

            var swipeElement = swipes[0];

            var slider = new Swipe(swipeElement, {
                startSlide: 0,
                auto: 6000,
                draggable: true,
                autoRestart: false,
                continuous: true,
                disableScroll: true,
                stopPropagation: true,
                callback: function(index) {
                    var progressElement = element.getElementsByClassName('current-slide');
                    if (progressElement.length > 0) {
                        progressElement[0].innerText = index + 1;
                    }
                }
            });

            var navigation = element.getElementsByClassName('navigate');
            for (var j = 0; j < navigation.length; j++) {
                var navElement = navigation[j];

                (function (el) {
                    el.addEventListener('click', function (e) {
                        if (e.preventDefault) {
                            e.preventDefault();
                        }

                        if (el.classList.contains('navigate-left')) {
                            slider.prev();
                        } else {
                            slider.next();
                        }
                    });
                })(navElement);
            }
        })(sliders[i]);
    }

    window.dispatchEvent(new Event('resize'))
}

setTimeout(loadSliders, 100);

//window.loadSliders = loadSliders;