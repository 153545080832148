var $sections = $('.expandable-sections').find('.section');
$sections.find('> a').click(function(e) {
    e.preventDefault();

    var $section = $(this).parents('.section');

    if ($section.hasClass('active')) {
        $section.removeClass('active');
    } else {
        $sections.removeClass('active');
        $section.addClass('active');
    }
});