
var links = document.querySelectorAll('.adaptation-menu a');
for (var i = 0; i < links.length; i++) {
    (function(link, index) {
        link.addEventListener('click', function (e) {
            if (e.preventDefault) {
                e.preventDefault();
            }

            // Display correct tab content
            var tabs = document.querySelectorAll('.adaptation-tab-content');
            for (var j = 0; j < tabs.length; j++) {
                tabs[j].classList.remove('active');
            }
            tabs[index].classList.add('active');

            // Highlight new tab menu item
            for (var j = 0; j < links.length; j++) {
                links[j].classList.remove('active');
            }
            links[index].classList.add('active');
        });
    })(links[i], i);
}

var tabs = document.getElementsByClassName('adaptation-tab-content');
for (var i = 0; i < tabs.length; i++) {
    (function (tab) {
        var choices = tab.querySelectorAll('li');
        for (var j = 0; j < choices.length; j++) {
            (function (choice) {
                var preview = tab.getElementsByClassName('preview')[0];
                var intro = tab.getElementsByClassName('intro')[0];

                if ($(choice).parents('.adaptation-group').hasClass('no-hover')) {
                    return;
                }

                choice.addEventListener('mouseover', function (e) {
                    preview.innerHTML = '';
                    preview.classList.remove('hide');
                    intro.classList.add('hide');

                    var imageNodes = choice.getElementsByClassName('image');
                    if (imageNodes.length > 1) {
                        var node = imageNodes[1];
                        preview.appendChild(node.cloneNode(true));
                    } else if (imageNodes.length > 0) {
                        var node = imageNodes[0];
                        preview.appendChild(node.cloneNode(true));
                    } else {
                        var colorNodes = choice.getElementsByClassName('color');
                        if (colorNodes.length > 0) {
                            var node = colorNodes[0];
                            preview.appendChild(node.cloneNode(true));
                        }
                    }
                });

                choice.addEventListener('mouseout', function (e) {
                    preview.innerHTML = '';
                    preview.classList.add('hide');
                    intro.classList.remove('hide');
                });


            })(choices[j]);
        }
    })(tabs[i]);
}