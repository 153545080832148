var $specifications = $('.specifications');

$specifications.each(function(index, specification) {
    var $specification = $(specification);
    var $tabs = $specification.find('.tabs');
    var $tabSelect = $tabs.find('.tab-select');
    $tabSelect.find('a').click(function(e) {
        e.preventDefault();

        var $li = $(this).parent();
        var index = $li.index();

        $tabSelect.find('li').removeClass('active');
        $li.addClass('active');

        var $contentBlocks = $tabs.find('.tab-content').find('> div');
        $contentBlocks.removeClass('active');
        $($contentBlocks[index]).addClass('active');
    });
});
